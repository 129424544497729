import React, { useState, useEffect } from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import Reflections from "./Reflections";

const App = () => {
  const [hostName, setHostName] = useState("");

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomainPart = hostname.split(".")[0];
    setHostName(subdomainPart);
    if (subdomainPart == "curation") {
      document.title =
        "Aspire | Solve employee related problems through habit formation.";
    } else if (subdomainPart == "reflectionsai") {
      document.title = "Reflections AI";
    }
  }, []);

  if (hostName == "curation") {
    return <Mobile />;
  } else if (hostName == "reflectionsai") {
    return <Reflections />;
  }
};
export default App;
